<template>
    <div class="check-clients-adress">
        <div class="main-win">
            <div class="main-bar">
                <div class="close" @click="close_win">
                    <i class="material-icons">cancel</i>
                </div>
                <p>עדכון כתובות ומיקומים</p>
            </div>
            <div class="options-bar">
                <el-button type="primary" class="btn" @click="get_clients_no_adress">טען לקוחות</el-button>
                <el-button type="default" class="btn" @click="filter = 'no adress'">הצג לקוחות ללא מיקום</el-button>
                <el-button type="default" class="btn" @click="filter = null">הצג הכל</el-button>
            </div>
            <div class="main-view">
                <div class="loading" v-if="loading">
                    <i class="material-icons">cached</i>
                    <p>טוען, אנא המתן...</p>
                </div>
                <table id="table">
                    <tr>
                        <th>לקוח</th>
                        <th>שם</th>
                        <th>כתובת</th>
                        <th>עיר</th>
                        <th>קו רוחב</th>
                        <th>קו אורך</th>
                        <th>איתור</th>
                    </tr>
                    <template v-for="client in clients_filter" :key="client.makat">
                        <tr>
                            <td>{{client.makat}}</td>
                            <td>{{client.name}}</td>
                            <td>{{client.adress}}</td>
                            <td>{{client.city}}</td>
                            <td v-if="client.location">{{client.location.lat}}</td>
                            <td v-else>חסר</td>
                            <td v-if="client.location">{{client.location.lng}}</td>
                            <td v-else>חסר</td>
                            <td>  
                                <el-button type="primary" class="btn" style="width:80px;" @click="update_lat_lng(client)">אתר</el-button>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../firebase/config';
import { computed } from '@vue/runtime-core';
import Swal from 'sweetalert2';
import { slide_pop_error, slide_pop_successs } from '../../Methods/Msgs';
export default {
emits:["close"],
setup(props, {emit}){
    const loading = ref(false);
    const clients = ref([]);
    const filter = ref(null);

    const close_win = () => {
        emit("close")
    }

    const get_clients_no_adress = async () =>{
        const docs = await projectFirestore.collection('Clients').get();
        if(!docs.empty){
            clients.value = docs.docs.map(doc => doc.data());
        }
    }

    const clients_filter = ref(computed(() => {
        if(!filter.value){
            return clients.value
        }
        if(filter.value == 'no adress'){
            return clients.value.filter(client => {
            return !client.location
            })
        }
    }))

    const update_lat_lng = async (client) => {
        loading.value = true;
        if(client.adress && client.city){
            let adress = client.adress +' '+client.city
            await fetch(encodeURI(`https://maps.googleapis.com/maps/api/geocode/json?address=${adress}&key=AIzaSyBMit_XjNJRBf8xmcTJHJYyd-N3t8moW9s`)).then(async res => {
                if(res.ok){
                    let json = await res.json()
                    if(json.status == 'OK'){
                        let location = {
                            lat: json.results[0].geometry.location.lat,
                            lng: json.results[0].geometry.location.lng,
                        }
                        client.location = location
                        await projectFirestore.collection('Clients').doc(client.makat).set(client, {merge:true});
                        slide_pop_successs("הכתובת אותרה בהצלחה!")
                    }else{
                        slide_pop_error("לא ניתן לאתר את הכתובת, אנא עדכן ונסה שנית.")
                    }
                }else{
                    slide_pop_error("לא ניתן לאתר את הכתובת, אנא נסה שנית מאוחר יותר.")
                }
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: 'כתובת הלקוח איננה תקינה, וודא שיש עיר וכתובת בכרטיס לקוח.'
            })
        }
        loading.value = false;
    }

    return{
        close_win, loading, get_clients_no_adress, clients, clients_filter, filter, update_lat_lng
    }
}
}
</script>

<style scoped>
.check-clients-adress{
    position: absolute;
    z-index: 10;
    background: rgba(0, 0, 0, 0.753);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.main-win{
    width: 100%;
    max-width: 700px;
    height: 90%;
    background: var(--secondary);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.377);
    display: flex;
    flex-direction: column;
}
.main-bar{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    flex-shrink: 0;
}
.close{
    position: absolute;
    top: 10px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--danger);
    cursor: pointer;
}
.options-bar{
    width: 100%;
    height: 80px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px;
}
.main-view{
    position: relative;
    width: 100%;
    height: calc(100% - 50px - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
}
.loading{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: var(--secondary);
    z-index: 2;
    color: white;
}
#table {
    border-collapse: collapse;
    width: 100%;
    table-layout:fixed;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
    overflow: hidden; 
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}
#table tr:hover {background-color: #ddd;}
#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
  position: sticky;
  top: 0;
}
.btn{
    margin: 0;
    margin-left: 5px;
}
</style>