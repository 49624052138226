<template>
    <ClientVisits v-if="show_clients_to_visit_win" @close="show_clients_to_visit_win = !show_clients_to_visit_win" :clients="clients_to_visit"/>
    <CheckClientAdresses v-if="show_clients_adresses_fix" @close="show_clients_adresses_fix = !show_clients_adresses_fix"/>

    <div class="loading" v-if="loading_map">
        <img src="@/assets/loader.gif" alt="">
        <p class="sub-title heartbeat">טוען... אנא המתן</p>
    </div>

    <div class="week-selector">
        <el-select v-model="selected_week" placeholder="שבוע">
            <el-option v-for="week in weeks" :key="week" :label="week" :value="week">
            </el-option>
        </el-select>
    </div>

    <div class="agents-panel" id="agents-panel">
        <div class="minimize-panel" @click="minimize_panel">
            <i class="material-icons" style="font-size:14px; margin-left: 5px;">
            close_fullscreen
            </i>
            <p>מזער</p>
        </div>
        <div class="agents-icons">
            <template v-for="agent in agents" :key="agent.uid">
                <div class="agent-icon">
                    <div class="online" v-if="agent.state == 'visible'"></div>
                    <div class="offline" v-if="agent.state != 'visible'"></div>
                    <img :src="agent.profile_image" alt="" @click="selected_agent = agent">
                    <p>{{agent.name}}</p>
                    <div class="agent-toggle">
                        <p style="background:var(--success); padding: 3px;" @click="add_clients_to_map(agent)" v-if="!clients_of_agents_shown.includes(agent.uid)">הצג לקוחות</p>
                        <p style="background:var(--danger); padding: 3px;" @click="remove_agent_client_markers(agent)" v-if="clients_of_agents_shown.includes(agent.uid)">הסתר</p>
                    </div>

                </div>
            </template>
        </div>
    </div>

    <div class="agent-options" v-if="selected_agent">
        <div class="close-agents-option" @click="selected_agent = null">
            <i class="material-icons">close</i>
        </div>
        <div class="agent-btn" @click="show_agent_clients">
            <i class="material-icons">explore</i>
            <p>הצג לקוחות</p>
        </div>
        <div class="agent-btn" @click="show_agent_clients">
            <i class="material-icons">alt_route</i>
            <p>הצג מסלול</p>
        </div>
    </div>

    <div class="minimize-ctv-panel" @click="minimize_sales_panel" style="margin-bottom: 5px;">
        <i class="material-icons" style="font-size:14px; margin-left: 5px;">
        close_fullscreen
        </i>
        <p>מזער</p>
    </div>
    <div class="clients-to-visit" id="clients-to-visit-p" v-if="clients_to_visit">
        <p>לקוחות לבקר היום: {{clients_to_visit.route_clients.length}}</p>
        <p>בוקרו במסלול: {{clients_to_visit.route_clients.length - clients_to_visit.clients_to_attend.length}}</p>
        <p>בוקרו לא במסלול: {{clients_to_visit.off_route_clients.length}}</p>
        <div class="saparator"></div>
        <p>אחוז ביקור (מסלול): {{((clients_to_visit.route_clients.length - clients_to_visit.clients_to_attend.length) / clients_to_visit.route_clients.length * 100).toFixed(2)}}%</p>
        <p>עשו הזמנה: {{clients_to_visit.route_orders.length + clients_to_visit.off_route_clients.length}} לקוחות</p>
        <p>אחוז הזמנה: {{((clients_to_visit.route_orders.length + clients_to_visit.off_route_clients.length) * 100 / 
            (clients_to_visit.route_clients.length - clients_to_visit.clients_to_attend.length + clients_to_visit.off_route_clients.length)).toFixed(2)}}%</p>
        <p></p>
        <div class="saparator"></div>
        <p>סה"כ מלקוחות במסלול:</p>
        <div class="total-order-amount">
            <p>{{clients_to_visit.total_route_orders.toFixed(2)}} ₪</p>
        </div>
        <p>סה"כ מלקוחות לא במסלול:</p>
        <div class="total-order-amount" style="color:var(--danger);">
            <p>{{clients_to_visit.total_off_route_orders.toFixed(2)}} ₪</p>
        </div>
        <p style="margin-top: 5px;">סה"כ מכירות סוכנים:</p>
        <div class="total-income">
            <p>{{clients_to_visit.total_sales.toFixed(2)}} ₪</p>
        </div>

        <p style="margin-top: 5px;">סה"כ החזרות סוכנים:</p>
        <div class="total-income" style="background:var(--danger);nja">
            <p>{{clients_to_visit.total_returns.toFixed(2)}} ₪</p>
        </div>
        <div class="clients-to-visit-toolbar">
            <div class="clients-visit-btn" @click="get_clients_to_visit">
                <i class="material-icons" style="margin-left:3px; font-size:16px;">autorenew</i>
                <p>רענן</p>
            </div>
            <div class="clients-visit-btn" @click="show_clients_to_visit_win = !show_clients_to_visit_win">
                <i class="material-icons" style="margin-left:3px; font-size:16px;">format_list_bulleted</i>
                <p>הצג רשימה</p>
            </div>
        </div>
        <p style="font-size:14px; margin-top:3px; width:100%; text-align:center; color:var(--yellow);">מעודכן ל: {{clients_to_visit.date.toLocaleString('he')}}</p>
        <div class="clients-visit-btn" style="width:100%; margin-top:10px; justify-content:center;" @click="show_agents_visits = !show_agents_visits">
            <i class="material-icons" style="margin-left:3px; font-size:16px;">people</i>
            <p>הצג/הסתר סוכנים</p>
        </div>
        <div class="clients-visit-btn" style="width:100%; margin-top:10px; justify-content:center; background:var(--alert-purple);" 
            @click="show_clients_adresses_fix = !show_clients_adresses_fix">
            <i class="material-icons" style="margin-left:3px; font-size:16px;">gps_fixed</i>
            <p>ניהול מיקומי לקוחות</p>
        </div>
        <div class="toggle_orders" @click="show_orders_on_map" id="toggle_orders">
            הצג הזמנות
        </div>
        <div class="orders-tour" @click="orders_tour" id="orders-tour" v-if="orders_markers.length > 0">
            התחל סיור הזמנות
        </div>
        <div class="add-temp-client" @click="add_temp_client_to_map">
            הוסף לקוח זמני
        </div>
    </div>

    <div class="clients-to-visit-agents" v-if="show_agents_visits">
        <template v-for="agent in visits_per_agent" :key="agent.id">
            <div class="agent-visits-box">
                <div class="agent-title">
                    <p style="background:var(--main); padding: 0 5px 0 5px; margin-left:5px;">{{agent.agent}}</p>
                    <p>{{agent.name}}</p>
                </div>
                <p style="color:rgb(211, 211, 211);">לקוחות לבקר: {{agent.total_clients_to_visit}} </p>
                <p style="color:rgb(211, 211, 211);">בוקרו במסלול: {{agent.on_route_clients}}</p>
                <p style="color:rgb(211, 211, 211);">מחוץ למסלול: <span style="color:white;">{{agent.off_route_clients}}</span></p>
                <p style="color:rgb(211, 211, 211);">סה"כ מכירות: <span style="color:var(--success);">{{agent.total_sales.toFixed(2)}} ₪</span></p>
                <p style="color:rgb(211, 211, 211);">סה"כ החזרות: <span style="color:var(--danger);">{{agent.total_returns.toFixed(2)}} ₪</span></p>
                
            </div>
            <div class="saparator"></div>
        </template>
    </div>

    <div class="locations">
        <div id="map" style="width:100%; height:100%">

        </div>
    </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from '@vue/runtime-core';
import { projectFirestore, projectFunctions } from '../../firebase/config';
import { slide_pop_error, slide_pop_successs } from '../../Methods/Msgs';
import ClientVisits from './ClientsToVisit.vue';
import CheckClientAdresses from '../../components/Locations/CheckClientAdress.vue';

import Swal from 'sweetalert2';
export default {
components:{ClientVisits, CheckClientAdresses},
//כפתור שמנקה את המפה ומשאיר רק את המשתמשים
setup(){
    let map;
    const loading_map = ref(false)
    const agents = ref([]);
    const locations = ref([]);
    const selected_agent = ref(null)
    const markers = ref([]);
    const client_markers = ref([]);

    const clients_to_visit = ref();

    const clients_of_agents_shown = ref([])

    const weeks = ['הכל', 'שבוע ד', 'שבוע ג', 'שבוע ב', 'שבוע א']
    const selected_week = ref('')

    const last_opened_infoWindow = ref(null);

    const location_snapshot = projectFirestore.collection('Locations').onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
            if(change.type == 'added'){
                locations.value.push(change.doc.data())
            }
            if(change.type == 'modified'){
                console.log('loc changed');
                let inx = locations.value.findIndex(loc => {
                    return loc.uid == change.doc.id
                })
                console.log(inx);
                if(inx != -1){
                    locations.value[inx] = change.doc.data()
                    update_marker(change.doc.id, locations.value[inx])
                }
                
            }
        })
    })

    const agents_snapshot = projectFirestore.collection('Users').where('agent', '!=', 0).onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
            if(change.type == 'added'){
                setTimeout(() => {
                    agents.value.push(change.doc.data())
                    if(agents.value[agents.value.length - 1].state == 'visible'){
                        let pos_inx = locations.value.findIndex(loc => {
                            return loc.uid == change.doc.id
                        })
                        if(pos_inx != -1){
                            agents.value[agents.value.length - 1].pos = locations.value[pos_inx]
                            add_agent_marker(agents.value[agents.value.length - 1])
                        }
                    }
                }, 1000);
            }
            if(change.type == 'modified'){
                let inx = agents.value.findIndex(agnt => {
                    return agnt.uid == change.doc.id
                })
                if(inx != -1){
                    agents.value[inx] = change.doc.data()
                    if(agents.value[inx].state == 'visible'){
                        slide_pop_successs(`${agents.value[inx].name} התחבר למערכת`)
                        //dont have marker yet
                        if(markers.value.findIndex(mark => mark.uid == change.doc.id) == -1){
                            let pos_inx = locations.value.findIndex(loc => {
                                return loc.uid == change.doc.id
                            })
                            if(pos_inx != -1){
                                agents.value[inx].pos = locations.value[pos_inx]
                                add_agent_marker(agents.value[inx])
                            }
                        }   
                    }else{
                        slide_pop_error(`${agents.value[inx].name} התנתק מהמערכת`)
                        remove_agent_marker(agents.value[inx]);
                    }
                }
            }
            sort_agents();
        })
    })

    const sort_agents = () => {
        agents.value = agents.value.sort((aa,bb) => {
            return aa.state - bb.state
        })
    }

    const add_agent_marker = (agent) => {
        var icon = {
            url: agent.profile_image, // url
            scaledSize: new google.maps.Size(50, 50), // scaled size
            origin: new google.maps.Point(0,0), // origin
            anchor: new google.maps.Point(0, 0),// anchor,
        };
        const marker = new google.maps.Marker({
            position: { lat: agent.pos.lat, lng:  agent.pos.lng},
            map: map,
            icon: icon,
            title: agent.name,
            uid: agent.uid,
            animation: google.maps.Animation.DROP,
        })
        markers.value.push(marker);
    }

    const remove_agent_marker = (agent) => {
        let inx = markers.value.findIndex(mark => {
            return mark.uid == agent.uid
        })
        if(inx != -1){
            markers.value[inx].setVisible(false);
            markers.value.splice(inx, 1);
        }
    }

    const update_marker = (uid, position) => {
        let inx = markers.value.findIndex(mark => {
            return mark.uid == uid
        })
        if(inx != -1){
            markers.value[inx].setPosition({ lat: position.lat, lng:  position.lng})
        }
    }

    function initMap() {
        map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 32.8141723, lng:  35.0644051},
            zoom: 13,
            scaleControl: true,
        });

        const home_base_marker = new google.maps.Marker({
            position: { lat: 32.8138178, lng:  35.0633996},
            map: map,
            icon: "https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/App%2Flogo_80_375x375.png?alt=media&token=9c425c51-7715-4174-a8d2-4846fbf52a30",
            title: "מטה רוסמן לוגיסטיקה"
        })
    }

    const minimize_panel = () => {
        const el = document.getElementById('agents-panel')
        if(el){
            if(el.style.height == '30px'){
                el.style.height = '80%';
                el.children[0].children[1].innerHTML = 'מזער';
                
            }else{
                el.style.height = '30px';
                el.children[0].children[1].innerHTML = 'פתח';
            } 
        }
    }

    const minimize_sales_panel = () => {
        const el = document.getElementById('clients-to-visit-p')
        if(el){
            if(el.style.display == 'none'){
                el.style.display = 'flex';
            }else{
                el.style.display = 'none';
            } 
        }
    }

    const show_agent_clients = async () => {
        if(selected_agent.value){
            loading_map.value = true;
            if(!selected_agent.value.clients){
                await get_agents_clients();
            }
            await remove_all_client_markers();
            await create_clients_markers()
            loading_map.value = false;
        }
    }

    const add_clients_to_map = async (agent) => {
        loading_map.value = true;
        selected_agent.value = agent;
        await get_agents_clients();
        await create_clients_markers();
        loading_map.value = false;
        selected_agent.value = null;
        
    }

    const get_agents_clients = async () => {
        console.log('Getting clients');
        const docs = await projectFirestore.collection('Clients').where('agent', '==', Number(selected_agent.value.agent)).get()
        if(!docs.empty){    
            selected_agent.value.clients = docs.docs.map(doc => doc.data());
            clients_of_agents_shown.value.push(selected_agent.value.uid)
        }else{
            Swal.fire({
                icon: 'error',
                title: 'לא נמצאו לקוחות'
            })
        }
    }

    const create_clients_markers = async () => {
        const colors = ["red", "blue", "green", "brown", "black", "slateblue", "grey", "goldenrod"]
        if(selected_agent.value && selected_agent.value.clients){
            let problems = [];
            let color = colors[Math.floor(Math.random() * colors.length)];
            for(let i =0; i <selected_agent.value.clients.length; i++){
                let client = selected_agent.value.clients[i];
                if(client.location){
                    const svgMarker = {
                        path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                        fillColor: color,
                        fillOpacity: 0.8,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: 1,
                        anchor: new google.maps.Point(0, 0),
                    };

                    const marker = new google.maps.Marker({
                        icon: svgMarker,
                        position: { lat: client.location.lat, lng:  client.location.lng},
                        map: map,
                        title: client.name,
                        visitation: client.visitation,
                        uid: selected_agent.value.uid,
                        animation: google.maps.Animation.DROP,
                        label: {text: selected_agent.value.agent +' '+ client.visitation.DAYNAME, color: "white"}
                    })
                    client_markers.value.push(marker);
                }else{
                    problems.push(client)
                }
            }
            console.log(problems);
            let temp_week = selected_week.value;
            selected_week.value = 'הכל';
            setTimeout(() => {
                selected_week.value = temp_week;
            }, 250);
            return true
        }
    }

    const remove_all_client_markers = async () => {
        if(client_markers.value.length > 0){
            for(let i = 0; i < client_markers.value.length; i++){
                let marker = client_markers.value[i]
                marker.setVisible(false);
            }
            client_markers.value = [];
        }
    }   

    const remove_agent_client_markers = async (agent) => {
        if(client_markers.value.length > 0){
            for(let i = 0; i < client_markers.value.length; i++){
                let marker = client_markers.value[i]
                if(marker.uid == agent.uid){
                    marker.setVisible(false);
                }
            }
            client_markers.value = client_markers.value.filter(mark => mark.uid != agent.uid)
            clients_of_agents_shown.value = clients_of_agents_shown.value.filter(uid => uid != agent.uid)
        }
    }   

    const get_clients_to_visit = async() => {
        slide_pop_successs("טוען לקוחות וביקורי לקוחות")
        const clients = await projectFunctions.httpsCallable("client_to_visit_today")();
        clients_to_visit.value = clients.data
        clients_to_visit.value.date = new Date()
        slide_pop_successs("ביקורי סוכנים אצל לוקוחות נטענו בהצלחה")
    }

    const orders_markers = ref([]);
    const show_orders_on_map = async () => {
        const el = document.getElementById('toggle_orders');

        if(orders_markers.value.length == 0){
            el.innerText = 'הסתר הזמנות'
            if(clients_to_visit.value.orders){
                for(let  i =0; i<clients_to_visit.value.orders.length; i++){
                    let order = clients_to_visit.value.orders[i]
                    if(order.lat && order.order_amount > 0){
                        await create_order_marker(order)
                    }
                }
            }
        }else{
            el.innerText = 'הצג הזמנות'
            for(let  i =0; i< orders_markers.value.length; i++){
                orders_markers.value[i].setVisible(false);
            }
            orders_markers.value = [];
        }

    }

    const create_order_marker = async (order) => {
        const content_string = `<div style="width: 370px; display:flex; flex-direction:column; overflow:hidden; height:180px;">
                                <p style="width:100%; text-align:center; font-size: 20px; background:var(--secondary); color:white;">${order.name}</p>
                                <p style="font-size: 18px;">מס לקוח: ${order.client}</p>
                                <p style="font-size: 18px;">סוכן: ${order.agent_name}</p>
                                <p style="font-size: 18px;">מספר הזמנה: ${order.order}</p>
                                <p style="font-size: 18px;">מועד ביקור: ${new Date(order.visit_date).toLocaleString('he')}</p>
                                <p style="font-size: 18px;">הערות הזמנה: ${order.subject}</p>
                                <p style="font-size: 26px;">סכום: ${order.order_amount}</p>
                                </div>`
        const svgMarker = {
            path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#28c76f',
            fillOpacity: 0.8,
            strokeWeight: 0,
            rotation: 0,
            scale: 1,
            anchor: new google.maps.Point(0, 0),
        };

        const infowindow = new google.maps.InfoWindow({
            content: content_string,
        });

        let inx = orders_markers.value.findIndex(marker => {
            return marker.lat == order.lat
        })
        if(inx != -1){
            order.lat = Number(order.lat) + 0.0001
        }
        const marker = new google.maps.Marker({
            icon: svgMarker,
            position: { lat: Number(order.lat), lng: Number(order.lng)},
            map: map,
            lat: order.lat,
            title: order.name +' ('+ order.agent +')' ,
            order_data: order,
            order_id: order.order,
            animation: google.maps.Animation.DROP,
            label: {text: order.agent, color: "black"},
        })

        marker.addListener("click", async () => {
            map.panTo(marker.getPosition());
            await smooth_zoom(16)
            infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
            });
            last_opened_infoWindow.value = infowindow;
        });

        orders_markers.value.push(marker);
    }

    const smooth_zoom = async (target) => {
        let current_zoom = map.getZoom();
        if(target > current_zoom){
            for(let i = current_zoom; i < target; i++){
                setTimeout(() => {
                    map.setZoom(i)
                }, (target - current_zoom)*50);
            }
        }else{
            for(let i = current_zoom; i > target; i--){
                setTimeout(() => {
                    map.setZoom(i)
                }, (target - current_zoom)*50);
            }
        }

    }

    const orders_tour = async () => {
        orders_markers.value = orders_markers.value.sort((aa, bb) => {
            return Number(aa.order_data.agent) - Number(bb.order_data.agent)
        })

        minimize_sales_panel();
        minimize_panel();
        await pausa(1000);

        for(let  i =0; i< orders_markers.value.length; i++){
            google.maps.event.trigger(orders_markers.value[i], 'click')
            setTimeout(() => {
                last_opened_infoWindow.value.close();
                return
            }, 2500);
            await pausa(2500);
            await smooth_zoom(10);
            await pausa(1000);
        }
        minimize_sales_panel();
        minimize_panel();
    }

    const pausa = async (time_ms) => {
        await new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, time_ms);
        })
    }

    const geocode = async (adress) => {
        var HttpProxyAgent = require('http-proxy-agent');
        //toekn from https://www.dotcom.co.il/tools/googlegeocoder/
        // proxy https://www.sslproxies.org/
        const req = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${adress}&key=AIzaSyDHjUfZwuGezmbSo7m0ugBjXiDbp63bkfg`
            , {agent: new HttpProxyAgent('62.252.146.74:8080')})
        const json = await req.json()
        return json
    }

    const add_temp_client_to_map = async () => {
        // Swal.fire({
        //     icon: 'question',
        //     title:"הוספת לקוח זמני למפה",
        //     html:   '<div style="width:100%; margin: none;">' +
        //             '<p style="max-width:100%; text-align:center;"> הכנס קווי אורך ורוחב של הלקוח<br> <a href="https://www.dotcom.co.il/tools/googlegeocoder/" target="_blank">לחץ כאן לאיתור הקווים</a></p>' +
        //             '<input id="lat" class="swal2-input" placeholder="קו אורך (LAT)" style="padding:0; text-align:center;">' +
        //             '<input id="lng" class="swal2-input" placeholder="קו רוחב (LNG)" style="padding:0; text-align:center;">' +
        //             ''+
        //             '</div>',
        //     preConfirm: () => {
        //         let position={
        //             lat: document.getElementById('lat').value,
        //             lng: document.getElementById('lng').value,
        //         } 
        //         if(!position.lat || !position.lng){
        //             slide_pop_error('אנא מלא את כלל השדות!');
        //             return false
        //         }else{
        //             slide_pop_successs('מעדכנים את המפה כעת...')
        //             return [position]
        //         }
        //     },
        //     showCancelButton: true,
        //     cancelButtonText: 'ביטול',
        //     confirmButtonText: 'אישור'
        // }).then(async (res) => {
        //     if(res.isConfirmed){
        //         create_temp_marker(res.value[0]);
        //     }
        // })
        Swal.fire({
            icon: 'question',
            title:"הוספת לקוח זמני למפה",
            html:   '<div style="width:100%; margin: none;">' +
                    '<p style="max-width:100%; text-align:center;"> הכנס כתובת כמה שיוץר מדוייקת</p><br>' +
                    '<input id="adress" class="swal2-input" placeholder="כתובת לקוח זמני" style="padding:0; text-align:center;">' +
                    '</div>',
            preConfirm: async () => {
                let adress= document.getElementById('adress').value
                if(!adress){
                    slide_pop_error('אנא מלא את כלל השדות!');
                    return false
                }else{
                    return [adress]
                }
            },
            showCancelButton: true,
            cancelButtonText: 'ביטול',
            confirmButtonText: 'אישור',
            denyButtonText: 'נקה זמניים מהמפה',
            showDenyButton: true
        }).then(async (res) => {
            if(res.isConfirmed){
                let adress = res.value[0]
                console.log(adress);
                var results = await geocode(adress)
                console.log(results);
                if(results && results.status == "OK"){
                    let position = {
                        lat: results.results[0].geometry.location.lat,
                        lng: results.results[0].geometry.location.lng,
                    }
                    slide_pop_successs('מעדכנים את המפה כעת...')
                    create_temp_marker(position);
                }
                else{
                    slide_pop_error('הייתה בעיה באיתור הקווים, נסה שוב או בדוק כתובת.');
                    return false
                }
                
            }
            if(res.isDenied){
                temp_markers.value.forEach(marker => {
                    marker.setVisible(false)
                })
                setTimeout(() => {
                    temp_markers.value = [];
                }, 1000);
            }
        })
    }

    const temp_markers = ref([]);
    const create_temp_marker = (location) => {
        const marker = new google.maps.Marker({
            icon: {url:"https://icon-library.com/images/my-cart-icon/my-cart-icon-5.jpg", scaledSize: new google.maps.Size(50, 50)},
            position: { lat: Number(location.lat), lng: Number(location.lng)},
            map: map,
            title: 'לקוח זמני' ,
            animation: google.maps.Animation.DROP,
            label: {text: 'לקוח זמני', color: "black"},
        })
        temp_markers.value.push(marker);
    }

    const show_clients_to_visit_win = ref(false);
    const show_clients_adresses_fix = ref(false);
    const show_agents_visits = ref(false);

    const visits_per_agent = ref(computed(() => {
        let agents = [];
        if(clients_to_visit.value){
            // set all agents:
            for(let i=0; i <clients_to_visit.value.clients.length; i++){
                if(agents.findIndex(agent => {
                    return agent.agent == clients_to_visit.value.clients[i].agent
                }) == -1){
                    agents.push({
                        agent: clients_to_visit.value.clients[i].agent,
                        name: clients_to_visit.value.clients[i].agent_name,
                        total_sales: 0,
                        total_returns: 0,
                        off_route_clients: 0,
                        on_route_clients: 0,
                        total_clients_to_visit: 0
                    })
                }
            }
            // clients to visit today:
            for(let i=0; i <clients_to_visit.value.route_clients.length; i++){
                let inx = agents.findIndex(agent => {
                    return agent.agent == clients_to_visit.value.route_clients[i].agent
                })
                agents[inx].total_clients_to_visit += 1;
                if(clients_to_visit.value.route_clients[i].order_amount > 0 || clients_to_visit.value.route_clients[i].order > 0){
                    agents[inx].on_route_clients += 1;
                }
            }
            // clients visited of route:
            for(let i=0; i <clients_to_visit.value.off_route_clients.length; i++){
                let inx = agents.findIndex(agent => {
                    return agent.agent == clients_to_visit.value.off_route_clients[i].agent
                })
                agents[inx].off_route_clients += 1;
            }
            // sales to all clients:
            for(let i=0; i <clients_to_visit.value.orders.length; i++){
                let inx = agents.findIndex(agent => {
                    return agent.agent == clients_to_visit.value.orders[i].agent
                })
                if(inx != -1){
                    if(clients_to_visit.value.orders[i].order_amount > 0){
                        agents[inx].total_sales += clients_to_visit.value.orders[i].order_amount
                    }
                    if(clients_to_visit.value.orders[i].order_amount < 0){
                        agents[inx].total_returns += clients_to_visit.value.orders[i].order_amount
                    }
                }
            }

            //returns from all clients:

        }
        
        agents = agents.sort((aa,bb) => {
            return aa.agent - bb.agent
        })
        return agents
    }))

    watch(selected_week, () => {
        if(selected_week.value == 'שבוע א'){
            if(client_markers.value.length > 0){
                client_markers.value.forEach(marker => {
                    if(marker.visitation.WEEK1 != 'Y'){
                        marker.setVisible(false)
                    }else{
                        marker.setVisible(true)
                    }
                })
            }
        }
        if(selected_week.value == 'שבוע ב'){
            if(client_markers.value.length > 0){
                client_markers.value.forEach(marker => {
                    if(marker.visitation.WEEK2 != 'Y'){
                        marker.setVisible(false)
                    }else{
                        marker.setVisible(true)
                    }
                })
            }
        }
        if(selected_week.value == 'שבוע ג'){
            if(client_markers.value.length > 0){
                client_markers.value.forEach(marker => {
                    if(marker.visitation.WEEK3 != 'Y'){
                        marker.setVisible(false)
                    }else{
                        marker.setVisible(true)
                    }
                })
            }
        }
        if(selected_week.value == 'שבוע ד'){
            if(client_markers.value.length > 0){
                client_markers.value.forEach(marker => {
                    if(marker.visitation.WEEK4 != 'Y'){
                        marker.setVisible(false)
                    }else{
                        marker.setVisible(true)
                    }
                })
            }
        }
        if(selected_week.value == 'הכל'){
            if(client_markers.value.length > 0){
                client_markers.value.forEach(marker => {
                    marker.setVisible(true)
                })
            }
        }
    })

    onMounted(() => {
        initMap();
        get_clients_to_visit();
    })

    onUnmounted(() => {
        location_snapshot();
        agents_snapshot();
    })

    return{
        minimize_panel, agents, selected_agent, show_agent_clients, loading_map,
        add_clients_to_map, clients_of_agents_shown, remove_agent_client_markers, clients_to_visit,
        get_clients_to_visit, show_clients_to_visit_win, show_clients_adresses_fix,
        minimize_sales_panel, show_agents_visits, visits_per_agent, weeks, selected_week, show_orders_on_map,
        orders_markers, orders_tour, add_temp_client_to_map
    }
}
}
</script>

<style scoped>
.locations{
    position: relative;
    width: 100%;
    height: 100%;
}
.agents-panel{
    position: absolute;
    top: 60px;
    left: 5px;
    width: 90px;
    height: 80%;
    background: var(--secondary);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.241);
    z-index: 5;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    transition: 0.25s;
}
.map{
    position: relative;
}
.loading{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.568);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.658);
    z-index: 100;
    font-size: 26px;
}
.loading img{
    max-width: 160px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.37);
}
.minimize-panel{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    cursor: pointer;
    background: var(--main);
}
.agents-icons{
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}
.agent-icon{
    position: relative;
    width: 100%;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0;
    color: white;
    margin-bottom: 10px;
}
.agent-icon img{
    max-width: 90px;
    max-height: 70px;
    border-radius: 10px;
    cursor: pointer;
}
.online{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 5px;
    background: var(--success);
    box-shadow: 0 0 5px var(--success);
    border-radius: 50%;
}
.offline{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 5px;
    background: var(--danger);
    box-shadow: 0 0 5px var(--danger);
    border-radius: 50%;
}
.agent-options{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
    height: 300px;
    background: var(--secondary);
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.575);
    display: grid;
    grid-template-rows: 49% 49%;
    grid-template-columns: 32% 32% 32%;
    gap: 5px;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.close-agents-option{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--danger);
    background: whitesmoke;
    cursor: pointer;
}
.agent-btn{
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    background: var(--success);
    border-radius: 10px;
    color: whitesmoke;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.658);
    cursor: pointer;
}
.agent-btn i{
    font-size: 40px;
}
.agent-toggle{
    width: 100%;
    height: 25px;
    text-align: center;
    cursor: pointer;
}
.clients-to-visit{
    position: absolute;
    width: 200px;
    height: 620px;
    background: var(--secondary);
    top: 90px;
    right: 5px;
    z-index: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 5px;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.692);
    transition: 0.25s;
}
.minimize-ctv-panel{
    position: absolute;
    z-index: 5;
    top: 60px;
    right: 5px;
    width: 200px;
    padding: 5px 0 5px 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--main);
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.clients-to-visit-toolbar{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}
.clients-visit-btn{
    width: fit-content;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: white;
    background: var(--main);
    border-radius: 5px;
    cursor: pointer;
}
.total-order-amount{
    background: var(--main);
    color: var(--success);
    width: 100%;
    text-align: center;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.total-income{
    width: 100%;
    background: var(--success);
    text-align: center;
    font-size: 24px;
    text-shadow: 0 0 5px black;
    border-radius: 5px;
}
.saparator{
    width: 100%;
    border: 1px solid var(--warning);
    margin-top: 5px;
    margin-bottom: 5px;
}
.clients-to-visit-agents{
    position: absolute;
    width: 200px;
    height: calc(100% - 150px);
    background: var(--secondary);
    top: 60px;
    right: 215px;
    z-index: 1;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    overflow-y: auto;
    padding: 5px;
    box-shadow: 0 0 5px rgba(128, 128, 128, 0.692);
    transition: 0.25s;
}
.agent-title{
    width: 100%;
    font-size: 18px;
    color: var(--yellow);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.week-selector{
    width: 150px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 170px;
    background: white;
    z-index: 5;
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.404);
    display: flex;
    justify-content: center;
    align-items: center;
}

.toggle_orders{
    margin-top: 10px;
    width: 100%;
    height: 30px;
    background: var(--success);
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.404);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 3px black;
    border-radius: 5px;
    cursor: pointer;
    flex-shrink: 0;
}
.orders-tour{
    flex-shrink: 0;
    margin-top: 10px;
    width: 100%;
    height: 30px;
    background: var(--warning);
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.404);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 3px black;
    border-radius: 5px;
    cursor: pointer;
}
.add-temp-client{
    flex-shrink: 0;
    margin-top: 10px;
    width: 100%;
    height: 30px;
    background: var(--primary);
    box-shadow: 0 0 3px rgba(128, 128, 128, 0.404);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 0 0 3px black;
    border-radius: 5px;
    cursor: pointer;
}

</style>